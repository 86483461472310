export default {
	messages: {
		uk: {
			'Усі моделі': 'Усі моделі',
			'Переглянуті': 'Переглянуті',
			"ТОП моделі": 'ТОП моделі',
			'Модель': 'Модель',
			'Спочатку виберіть марку': 'Спочатку виберіть марку',
		},
		ru: {
			'Усі моделі': 'Все модели',
			'Переглянуті': 'Просмотренные',
			"ТОП моделі": 'TOP модели',
			'Модель': 'Модель',
			'Спочатку виберіть марку': 'Сначала выберите марку',
		},
	}
};
