import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'Country',
	i18n: require('./i18n').default,
	data: function () {
		return {
			mounted: false,
			searchedValue: ''
		};
	},
	components: {
		'autocomplite': require('../Autocomplete2/index.vue').default
	},
	computed: {
		...mapGetters('autoInter/search', {
			currentCountry: 'country',
			countries: 'countries',
		}),

		options() {
			let options = this.mounted ? this.countries.sort((a, b) => a.name.localeCompare(b.name, 'en')) : []
			if(this.mounted && !!this.searchedValue) {
				options = options.filter(el => el.name.toLowerCase().startsWith(this.searchedValue.toLowerCase().trim()))
			}

			return [{ label: this.$t('Країна'), options}]
		},

		selected: {
			get: function () {
				return this.currentCountry;
			},

			set: function (id) {
				const selectedModel = this.countries.find(el => el.id === Number(id));
				this.updateSearchFieldValue({ field: 'country', 'value': selectedModel });
			}
		},
		selectedId() {
			return this.selected?.id || null
		},
	},
	methods: {
		...mapActions('autoInter/search', {
			updateSearchFieldValue: 'updateSearchFieldValue',
			fetchFieldData: 'fetchFieldData',
		}),
		onSet(value) {
			this.selected = value
		},
		ga() {
			this.gaEvent('AutoInterPageNew', 'ClickOn_Country', 'SearchForm');
		},
		async init() {
			await this.fetchFieldData('country')
		},
		inputAutocomplete(value) {
			if(!value) {
				this.selected = null
			}
			this.searchedValue = value.trim()
		},
		escAutocomplete() {
			this.searchedValue = ''
		},
	},
	async mounted() {
		this.mounted = true;
		this.init()
	},
	serverPrefetch() {
		this.init();
	}
};
