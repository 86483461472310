export default {
	messages: {
		uk: {
			'Усі марки': 'Усі марки',
			'Переглянуті': 'Переглянуті',
			"TOП марки": 'TOP марки',
			'Марка': 'Марка',
		},
		ru: {
			'Усі марки': 'Все марки',
			'Переглянуті': 'Просмотренные',
			"TOП марки": 'TOP марки',
			'Марка': 'Марка',
		},
	}
};
