export default {
    messages: {
        uk: {
            'AUTO.RIA рекомендує': 'AUTO.RIA рекомендує',
            'Показати ще': 'Показати ще'
        },
        ru: {
            'AUTO.RIA рекомендує': 'AUTO.RIA рекомендует',
            'Показати ще': 'Показать еще'
        }
    }
}
