import betweenDate from "../../../mixins/betweenDate";

export default {
    name: 'MainBRK',
    mixins: [betweenDate],
    props: {
        mobileSrc: {
            type: String,
            default: null
        },
        desktopSrc: {
            type: String,
            default: null
        },
        linkProps: {
            type: Object,
            default: null
        },
        expiredDate: {
            type: Object,
            default: null,
        },
        gaEvents: {
            type: Array,
            default: null
        },
        slonikData: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            defaultMobileSrc: 'https://css.riastatic.com/images/ukr/smіlivіst/smіlivіst-m',
            defaultDesktopSrc: 'https://css.riastatic.com/images/ukr/smіlivіst/smіlivіst',
            defaultLinkProps: {}
        }
    },
    computed:{
        isActiveBanner() {
            return this.expiredDate && (this.showBetweenDate || this.showBetweenDateTime)
        },
        isLink() {
            return Boolean(this.componentProps?.href)
        },
        imgSrc() {
            if(!this.isActiveBanner || (!this.desktopSrc && !this.mobileSrc)) {
                return this.isMobile ? this.defaultMobileSrc : this.defaultDesktopSrc;
            }
            return this.isMobile ? this.mobileSrc : this.desktopSrc;
        },
        componentProps() {
            if (this.linkProps && this.isActiveBanner) {
                return {
                    ...this.defaultLinkProps,
                    ...this.linkProps
                }
            }
            return this.defaultLinkProps
        },
    },
    methods: {
        handleClick() {
            if(this.gaEvents) {
                this.gaEvent(...this.gaEvents);
            }
            if(this.slonikData) {
                this._slonik({
                    project_id: 1,
                    current_url: encodeURI(window.location.href),
                    ...this.slonikData
                });
            }
        }
    },
    mounted() {
        if(this.expiredDate) {
            const {start, end} = this.expiredDate;
            this.setTime(start, end)
        }
    }
};
