export default {
    name: 'CarBrandsItem',
    props: {
        item: {
            type: Object,
            required: true
        },
        svgIcon: {
            type: String,
            required: true
        }
    }
}
