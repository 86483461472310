module.exports = {
    data() {
        return {
            beforeDate: '',
            afterDate: '',
        }
    },
    computed: {
        showBetweenDate() {
            const format = 'YYYY-MM-DD';
            const time = this.m(new Date(), format);
            const before = this.m(this.beforeDate, format);
            const after = this.m(this.afterDate, format);
            return time.isBetween(before, after);
        },
        showBetweenDateTime() {
            const format = 'YYYY-MM-DD HH:mm';
            const time = this.m(new Date(), format);
            const before = this.m(this.beforeDate, format);
            const after = this.m(this.afterDate, format);
            return time.isBetween(before, after);
        },
    },
    methods: {
        setTime (beforeDate, afterDate) {
            this.beforeDate = beforeDate;
            this.afterDate = afterDate;
        }
    }
};

