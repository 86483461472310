export default {
    messages: {
        uk: {
            'Марки авто з Європи': 'Марки авто з Європи',
            'Показати ще': 'Показати ще',
            'авто →': 'авто →',
            'Згорнути': 'Згорути',
            'Всі марки': 'Всі марки'
        },
        ru: {
            'Марки авто з Європи': 'Марки авто из Европы',
            'Показати ще': 'Показать еще',
            'авто →': "авто →",
            'Згорнути': 'Свернуть',
            'Всі марки': 'Все марки'
        }
    }
}
