export default {
	messages: {
		uk: {
			'Усі країни': 'Усі країни',
			'Переглянуті': 'Переглянуті',
			"TOP країни": 'ТОП країни',
			'Країна': 'Країна',
		},
		ru: {
			'Усі країни': 'Все страны',
			'Переглянуті': 'Просмотренные',
			"TOP країни": 'TOP страны',
			'Країна': 'Страна',
		},
	}
};

