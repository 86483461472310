export default {
  data() {
	return {
	  selectedBanners: 0,
	  banners: [0],
	};
  },
  name: 'SearchFormBanner',
  mounted() {
	this.selectedBanners = this.banners[Math.floor(Math.random() * this.banners.length)];
  },
};
