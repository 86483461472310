import {mapGetters, mapActions} from 'vuex';
import getFromSearchHistory from '../../../../../helpers/getFromSearchHistory';

import Autocomplete from '../Autocomplete/index.vue';
import {suggestBrand} from "../../../../../store/queries/suggest";

export default {
	name: 'Brands',
	i18n: require('./i18n').default,
	props: {
		search_type: String,
	},
	data: function () {
		return {
			mounted: false,
			suggests: [],
			currentSuggest: '',
		};
	},
	components: {
		'vue-autocomplete': Autocomplete
	},
	computed: {
		...mapGetters({
			brandsAll: 'shared/brands/getAll',
			brandsPopular: 'shared/brands/popular',
			brandsForCategory: 'shared/brands/getByCategory',
			category: 'search/state/getCategory',
			getSpecificBrand: 'search/state/getSpecificBrand',
		}),
		optionsRaw() {
			return Object.values(this.brandsForCategory(this.category) || {});
		},
		options() {
			return this.optionsRaw.filter(({count} = {}) => count).sort((a, b) => a.name.localeCompare(b.name, 'en'));
		},
		optionsSearched() {
			if (this.mounted) {
				const idsSearched = [...new Set(getFromSearchHistory('brand'))];
				const options = this.optionsRaw;
				return idsSearched.reduce((result, item) => {
					const optionSearched = options.find(({value} = {}) => Number(value) === Number(item));
					if (optionSearched) {
						result.push(Object.assign({}, optionSearched));
					}
					return result;
				}, []);
			} else {
				return [];
			}
		},
		optionsPopular() {
			const category = this.category || 0;
			const popularIds = this.brandsPopular(category);
			if (Array.isArray(popularIds)) {
				const popularIdsHead = category === 1 || !category ? popularIds.slice(0, 20) : popularIds.slice(0, 10);
				return popularIdsHead.reduce((result, item) => {
					let {[item]: element} = this.brandsAll;
					if (element) {
						result.push(element);
					}
					return result;
				}, [])
					.sort((a, b) => a.name.localeCompare(b.name, 'en'));
			} else {
				return [];
			}
		},
		selected: {
			get: function () {
				return this.getSpecificBrand(0) || 0;
			},
			set: function (value) {
				this.setOnly(value);
			}
		},
		selectedName() {
			let {name = ''} = this.brandsAll[this.selected]
			|| this.suggests.find(({value}) => Number(this.selected) === Number(value))
			|| {};
			return name;
		}
	},
	methods: {
		...mapActions({
			fetch: 'shared/brands/fetchByCategory',
			fetchPopular: 'shared/brands/fetchPopularByCategory',
			setOnly: 'search/state/setOnlyBrand',
			suggestEntity: 'shared/suggests/entity',
		}),
		init() {
			this.selectedIndex = -1;
			this.suggestedIndex = -1;
			return Promise.all([
				this.fetch(this.category),
				this.fetchPopular(this.category)]
				.map(item => item.catch((error) => console.error(error.message)))
			);
		},
		onSet(payload) {
			this.selected = payload;
		},
		onSuggest(payload) {
			this.currentSuggest = payload;
			this.suggests.splice(0, Infinity);

			return suggestBrand({text: payload, category: this.category}, this.search_type)
				.then((response) => {
					if (this.currentSuggest === payload) {
						this.suggests.splice(0, Infinity, ...response);
					}
				})
		},
		ga() {
			this.gaEvent('MainPageNew', 'ClickOn_Brand', 'SearchForm');
		}
	},
	watch: {
		category() {
			this.init();
		}
	},
	serverPrefetch(){
		return this.init();
	},
	mounted() {
		this.mounted = true;
		this.init();
	},
};
