import translate from "./i18n";
import links from "../Main/brkLink";

export default {
    name: 'MainPageAutointer',
    metaInfo() {
        return {
            title: `AUTO.RIA – ${this.$t('Авто з Європи')}`,
            description: this.$t('Продаж автомобілів з-за кордону'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('Продаж автомобілів з-за кордону')
                },
                {
                    name: 'keywords',
                    content: this.$t('авто, auto')
                },
                {
                    property: 'og:site_name',
                    content: 'AUTO.RIA'
                },
                {
                    property: 'og:title',
                    content: `AUTO.RIA – ${this.$t('Авто з Європи')}`
                },
                {
                    property: 'og:description',
                    content: this.$t('Продаж автомобілів з-за кордону')
                },
                {
                    property: 'og:image',
                    content: 'https://css.riastatic.com/images/autoria512.png'},
                {
                    name: 'ROBOTS',
                    content: 'INDEX,FOLLOW'
                },
            ],
            link: [
                {rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/uk/autointer/'},
                {rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/autointer/'},
                {rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/uk/autointer/'},
                {rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/autointer/'},
                {rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/uk/autointer/'},
                {rel: 'canonical', href: 'https://auto.ria.com/uk/autointer/'}
            ]
        };
    },

    data() {
        return {
            expiredDate: {
                start: new Date('Thu Dec 22 2022 09:00:00 GMT+0200 (Eastern European Standard Time)'),
                end: new Date('Sat Jan 22 2023 23:59:59 GMT+0200 (Eastern European Standard Time)'),
            }
        };
    },

    components: {
        'search-form': require('./SearchForm/index.vue').default,
        'brk': require('../Main/BRK/index.vue').default,
        'rotator': require('./Rotator/index.vue').default,

        'list-countries': require('./ListCountries/index.vue').default,
        'car-brands': require('./CarBrands/index.vue').default,
        'recommends': require('../Main/Recommends/index.vue').default,
        'news': require('./News/index.vue').default,
        'verify-by-people': require('../Common/VerifiedByPeople/index.vue').default,
        'vue-banner': require('./SearchForm/Banner/index.vue').default,
    },

    computed: {
        brkLink() {
            let link = links[Math.floor(Math.random() * links.length)];
            if(link.linkProps.href && link.linkProps.href.indexOf('/') === 0) {
                link.linkProps.href = `${this.langPrefix}${link.linkProps.href}`;
            }
            return link;
        }
    },

    i18n: {
        ...translate
    }
};
