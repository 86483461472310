import translate from "./i18n";

export default {
    name: 'NewsItem',
    props: {
        item: {
            type: Object,
            required: true
        },
        isFavorite: {
            type: Boolean,
        },
        showInviteToFavorite: {
            type: Boolean,
        }
    },
    i18n: {
        ...translate
    }
}
