export default {
	messages: {
		uk: {
			'від': 'від',
			'до': 'до',
			'Рік': 'Рік',
		},
		ru: {
			'від': 'от',
			'до': 'до',
			'Рік': 'Год',
		},
	}
};
