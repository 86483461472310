import {mapGetters, mapActions} from 'vuex';

export default {
	i18n: require('./i18n').default,
	computed: {
		...mapGetters('autoInter/search',{
			year: 'year',
			years: 'years'
		}),
		from: {
			get: function () {
				return this.year?.from?.value || 0
			},
			set: function (value) {
				value = parseInt(value);
				let fromObj = this.years.find((el) => el.value === value)
				if((fromObj.value > this.to) && !!this.to) {
					this.setYears({
						field: 'year',
						'value': {
							from: this.year?.to,
							to: fromObj
						}
					})
				} else {
					this.setYears({field: 'year', 'value': { from: fromObj }})
				}
			}
		},
		to: {
			get: function () {
				return this.year?.to?.value || 0
			},
			set: function (value) {
				value = parseInt(value);
				let toObj = this.years.find((el) => el.value === value)
				if((toObj.value < this.from) && !!this.from) {
					this.setYears({ field: 'year', value: {
						to: this.year?.from,
						from: toObj
					}})
				} else {
					this.setYears({field: 'year', 'value': { to: toObj }})
				}
			}
		}
	},
	methods: {
		...mapActions({
			setOnlyYears: 'search/state/setOnlyYears'
		}),
		...mapActions('autoInter/search',{
			setYears: 'updateSearchFieldValue',
			fetchFieldData: 'fetchFieldData'
		}),
		async init() {
			await this.fetchFieldData('year')
		},
	},
	mounted() {
		this.init()
	},
	serverPrefetch() {
		return this.init()
	}
};
