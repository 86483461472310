import { mapGetters, mapActions} from "vuex";


export default {
	name: 'SearchForm',
	props: ['target'],
	components: {
		'auto-inter-form': require('./AutoInterForm/index.vue').default,
		'order-auto-form': require('./OrderAutoForm/index.vue').default,
		'search-tabs': require('./SearchTabs/index.vue').default,
		'banner': require('./Banner/index.vue').default,
		'header-messages': require('../../Common/VueHeader/HeaderMessages/index.vue').default,
	},
	computed: {
		...mapGetters('autoInter/search',{
			searchType: 'searchType',
			searchTypes: 'searchTypes',
		}),
		listTypes(){
			return Object.values(this.searchTypes)
		},
		typeComponent() {
			return this.searchType.type === 'order' ? 'order-auto-form' : 'auto-inter-form'
		}
	},

	methods: {
		...mapActions('autoInter/search',{
			updateSearchFieldValue: 'updateSearchFieldValue',
			resetSearchForm: 'resetSearchForm'
		}),
		changeType(type){
			if(this.searchType.type !== type) {
				this.updateSearchFieldValue({ field: 'searchType', value: this.searchTypes[type]})
				this.resetSearchForm()
				this.gaEvent(...this.searchTypes[type].gaEvents);
				this.$emit('stats-red-form', {eventId: 541, dataEvent: this.searchTypes[type].eventId});
			}
		}
	},
	i18n: require('./i18n').default,
};
