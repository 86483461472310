export default {
    messages: {
        uk: {
            'Автор:': 'Автор: {author}',
            'В Обране': 'В Обране',
            'Додано в': 'Додано в',
            'Обране': 'Обране',
            'Перейти': 'Перейти'
        },
        ru: {
            'Автор:': 'Автор: {author}',
            'В Обране': 'В избранное',
            'Додано в': 'Добавлено в',
            'Обране': 'Избранное',
            'Перейти': 'Перейти'
        }
    }
}
