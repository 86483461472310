import translate from "./i18n";
import  { getSvg } from '../../svg/carsBrands';
import { mapActions, mapGetters } from "vuex";

const OTHER_CATEGORY_ID = 79;

export default {
	name: 'CarBrands',
	data () {
		return {
			isOpen: false,
		}
	},
	components: {
		'car-brands-item': require('./Item/index.vue').default
	},
	computed: {
		...mapGetters('autoInter', {
			autoInterBrands: 'brands',
			autoInterBrandsLoading: 'brandsLoading'
		}),
		brands() {
			const otherCategory = this.autoInterBrands.filter(el => el.topReviewHits?.make_id === OTHER_CATEGORY_ID)
			const categoriesExceptOtherCategory = this.autoInterBrands.filter(el => el.topReviewHits?.make_id !== OTHER_CATEGORY_ID)

			return [...categoriesExceptOtherCategory, ...otherCategory]
		},
	},
	methods: {
		...mapActions('autoInter', {
			fetchAutoInterBrands: 'fetchAutoInterBrands'
		}),
		getSvgLogo(name) {
			return getSvg(name)
		},
		isShow(index){
			if(this.isOpen) return true;
			return this.isDesktop ? index < 26 : index < 9
		},
		async init() {
			return this.fetchAutoInterBrands()
		}
	},
	async mounted() {
		await this.fetchAutoInterBrands()
	},
	serverPrefetch() {
		return this.init()
	},
	i18n: {
		...translate
	}
};
