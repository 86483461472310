import {mapGetters, mapActions} from 'vuex';
import Autocomplete from "../Autocomplete/index";

export default {
	name: 'Categories',
	components: {
		'vue-autocomplete': Autocomplete
	},
	computed: {
		...mapGetters('autoInter/search', {
			currentCategory: 'category',
			categories: 'categories',
		}),
		selected: {
			get: function () {
				return this.currentCategory?.vehicleId || 0;
			},
			set: function (id) {
				const selectedCategory = this.categories.find(el => el.vehicleId === id)
				this.updateSearchFieldValue({ field:'category', value: selectedCategory });
			}
		},
	},
	methods: {
		...mapActions('autoInter/search' ,{
			fetchFieldData: 'fetchFieldData',
			updateSearchFieldValue: 'updateSearchFieldValue'
		}),
		async init(){
			await this.fetchFieldData('category');
		}
	},
	async mounted() {
		this.init()
	},
	serverPrefetch() {
		return this.init()
	},
	i18n: require('./i18n').default,
};
