import {mapGetters, mapActions} from 'vuex';

import Autocomplete from '../Autocomplete/index.vue';

export default {
	name: 'Brands',
	components: {
		'vue-autocomplete': Autocomplete
	},
	data() {
		return {
			suggests: []
		}
	},
	computed: {
		...mapGetters('autoInter/search',{
			currentBrand: 'brand',
			brands: 'brands',
		}),
		options() {
			if(Array.isArray(this.brands)) {
				return this.brands?.filter(({count} = {}) => count).sort((a, b) => a.name.localeCompare(b.name, 'en'))
			}
			return []
		},
		selected: {
			get: function () {
				return this.currentBrand;
			},
			set: function (value) {
				const brandData = this.options.find(el => el.id === Number(value))
				this.updateSearchFieldValue({ field: 'brand', 'value': brandData || null});
			}
		},
		selectedName() {
			return this.selected?.name || ''
		},
	},
	methods: {
		...mapActions('autoInter/search',{
			fetchFieldData: 'fetchFieldData',
			updateSearchFieldValue: 'updateSearchFieldValue'
		}),
		async init() {
			await this.fetchFieldData('brand')
		},
		onSet(payload) {
			this.selected = payload
		},
		onSuggest(payload) {
			this.suggests = this.brands.reduce((acc,el) => {
				const comparingUAName = el.name.toLowerCase().trim().startsWith(payload.toLowerCase().trim())
				const comparingENGName = el.eng.toLowerCase().trim().startsWith(payload.toLowerCase().trim())
				const comparingSlang = el.slang.toLowerCase().trim().startsWith(payload.toLowerCase().trim())

				if(comparingUAName || comparingENGName || comparingSlang) {
					acc.push(el)
				}
				return acc
			},[])
		},
		ga() {
			this.gaEvent('AutoInterPageNew', 'ClickOn_Brand', 'SearchForm');
		}
	},
	mounted() {
		this.init();
	},
	serverPrefetch() {
		return this.init()
	},
	i18n: require('./i18n').default
};
