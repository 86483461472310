export default {
	messages: {
		uk: {
			'Тип транспорту': 'Тип транспорту',
			'Будь-який': 'Будь-який'
		},
		ru: {
			'Тип транспорту': 'Тип транспорта',
			'Будь-який': 'Любой'
		},
	}
};
