export default {
	messages: {
		ru: {
			'html.buAuto.ot': 'от',
			'html.buAuto.do': 'до',
			'html.buAuto.god': 'Год',
		},
		uk: {
			'html.buAuto.ot': 'від',
			'html.buAuto.do': 'до',
			'html.buAuto.god': 'Рік',
		},
	}
};
