export default {
	messages: {
		ru: {
			'Поиск': 'Поиск',
		},
		uk: {
			'Поиск': 'Пошук',
		},
	}
};
