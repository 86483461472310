export default {
	messages: {
		uk: {
			'Всі': 'Всі',
			'Вживані': 'Вживані',
			'Нові': 'Нові',
			'Під пригон': 'Під пригон',
			'Не розмитнені': 'Не розмитнені',
			'За кордоном': 'За кордоном'
		},
		ru: {
			'Всі': 'Все',
			'Вживані': 'Б/у',
			'Нові': 'Новые',
			'Під пригон': 'Под пригон',
			'Не розмитнені': 'Не разтаможенные',
			'За кордоном': 'Из-за рубежа'
		},
	}
};
