export default {
    messages: {
        uk: {
            'Новини по темі': 'Новини по темі',
            'Ще новини': 'Ще новини'
        },
        ru: {
            'Новини по темі': 'Новости по теме',
            'Ще новини': 'Еще новости'
        }
    }
}
