export default {
	name: 'List',
	props: [
		'options', 'optionsTitle',
		'optionsSearched', 'optionsSearchedTitle',
		'optionsPopular', 'optionsPopularTitle',
		'isOptionsHidden',
		'hoveredIndex', 'selected',
		'emptyText',
	],
	methods: {
		select(event) {
			const {target: {dataset: {value} = {}} = {}} = event;
			this.$emit('set', value)
		},
	},
	computed: {
		optionsSafe() {
			return Array.isArray(this.options) ? this.options : [];
		},
		optionsSearchedSafe() {
			const list = Array.isArray(this.optionsSearched) ? this.optionsSearched : [];
			return list.slice(0, 5);
		},
		optionsPopularSafe() {
			return Array.isArray(this.optionsPopular) ? this.optionsPopular : [];
		},
	},
	updated() {
		if (!this.isOptionsHidden) {
			let item = this.$el.querySelector('.active');
			if (item) {
				this.$el.scrollTop = item.offsetTop - 50;
			}
		}
	}
};
