import {mapActions, mapGetters, mapMutations} from 'vuex';

const {get} = require('../../../../helpers/cookie');

export default {
	name: 'AutoInterForm',
	i18n: require('./i18n').default,
	props: [
		'target',
		'withVin',
		'indexName',
		'searchQuery',
		'searchType'
	],
	components: {
		'categories': require('./Categories/index.vue').default,
		'brands': require('./Brands/index.vue').default,
		'models': require('./Models/index.vue').default,
		'country': require('./Country/index.vue').default,
		'years': require('./Years/index.vue').default,
		'price': require('./Price/index.vue').default,
	},
	computed: {
		...mapGetters({
			getSearchParamsUsedAutoV1: 'search/state/getSearchParamsUsedAutoV1',
			getSearchParamsUsedAutoV2: 'search/state/getSearchParamsUsedAutoV2',
			getAutoInterSearchParams: 'autoInter/search/getAutoInterSearchParams'
		}),
		getAutoInterHref() {
			return Object.keys(this.getAutoInterSearchParams).reduce((acc, el, index) => {
				if (index !== 0) {
					acc += '&'
				}
				acc = acc + el + '=' + this.getAutoInterSearchParams[el]
				return acc
			}, `${this.langPrefix}${this.target}?`)
		}
	},
	methods: {
		...mapActions({
			getCatalogParams: 'search/state/getCatalogParams',
		}),
		...mapMutations({
			setSearchType: 'search/state/setType',
		}),
		async gotoSearch() {
			location.href = this.getAutoInterHref
		},
		gotoAdvancedSearch() {
			let cookie_ = Number(get('advanced_search_test'));
			if (cookie_ === 42) {
				let query = {};
				for (let key in this.getSearchParamsUsedAutoV2) {
					if (this.getSearchParamsUsedAutoV2.hasOwnProperty(key) && this.getSearchParamsUsedAutoV2[key]) {
						query[key] = this.getSearchParamsUsedAutoV2[key];
					}
				}
				this.$router.push({ path: `${this.langPrefix}/advanced-search/`, query});
			} else {
				let sq = Object.keys(this.getSearchParamsUsedAutoV1).filter(param => this.getSearchParamsUsedAutoV1[param]).map(param => `${param}=${this.getSearchParamsUsedAutoV1[param]}`);
				if (this.searchQuery) {
					sq = sq.concat(this.searchQuery.split('&'));
				}
				if (this.indexName) {
					sq.push(`indexName=${this.indexName}`);
				}
				location.href = `${this.langPrefix}/advanced-search/${sq ? `?${sq.join('&')}` : ''}`;
			}
		}
	}
};
