export default {
	messages: {
		ru: {
			'html.buAuto.ot': 'от',
			'html.buAuto.do': 'до',
			'html.buAuto.cena': 'Цена',
		},
		uk: {
			'html.buAuto.ot': 'від',
			'html.buAuto.do': 'до',
			'html.buAuto.cena': 'Ціна',
		},
	}
};
