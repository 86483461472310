import {mapGetters, mapActions} from 'vuex';

export default {
	name: 'Categories',
	data: function () {
		return {};
	},
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			categoryId: 'search/state/getCategory',
			categories: 'shared/categories/l10n',
			langId: 'lang/id'
		}),
		categoriesOptionsList() {
			return Object.values(this.categories)
				.sort((a, b) => a.index - b.index);
		},
		selected: {
			get: function () {
				return this.categoryId || 0;
			},
			set: function (value) {
				this.setCategory(value);
			}
		}
	},
	watch: {
		langId() {
			this.fetchCategories(this.langId);
		}
	},
	methods: {
		...mapActions({
			fetchCategories: 'shared/categories/fetch',
			setCategory: 'search/state/setCategory'
		}),
		init() {
			return this.fetchCategories();
		}
	},
	serverPrefetch(){
		return this.init();
	},
	mounted() {
		this.mounted = true;
		this.init();
	}
};
