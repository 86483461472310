export default {
	messages: {
		uk: {
			'від': 'від',
			'до': 'до',
			'Ціна': 'Ціна',
		},
		ru: {
			'від': 'от',
			'до': 'до',
			'Ціна': 'Цена',
		},
	}
};
