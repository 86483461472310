export default {
	messages: {
		ru: {
			'html.buAuto.poisk': 'Поиск',
		},
		uk: {
			'html.buAuto.poisk': 'Пошук',
		},
	}
};
