import {mapGetters, mapActions, mapMutations} from 'vuex';

const currency = 1;

export default {
	i18n: require('./i18n').default,
	computed: {
		...mapGetters('autoInter/search',{
			getPrice: 'price'
		}),
		from: {
			get: function () {
				const {from: value} = this.getPrice || {};
				return value
			},
			set: function (value) {
				value = parseInt(value);
				const from = Number.isFinite(value) ? value : undefined;
				const to = this.getPrice?.to || 0;
				this.setPrice({field: 'price', 'value': { to, from, currency }})
			}
		},
		to: {
			get: function () {
				const {to: value} = this.getPrice || {};
				return value
			},
			set: function (value) {
				value = parseInt(value);
				const to = Number.isFinite(value) ? value : undefined;
				const from = this.getPrice?.from || 0;
				this.setPrice({field: 'price', 'value': { to, from, currency }})
			}
		}
	},
	methods: {
		...mapActions('autoInter/search',{
			setPrice: 'updateSearchFieldValue',
		})
	}
};
