import { mapGetters, mapActions } from 'vuex'
import translate from "./i18n";

export default {
  name: 'News',
  components: {
    'news-item': require('./Item/index.vue').default
  },
  data() {
    return {
      timers: [],
      showFavoritePopups: []
    }
  },
  computed: {
    ...mapGetters({
      news: 'autoInter/news',
      favoritesIds: 'news/favoritesIds'
    })
  },
  methods: {
    ...mapActions({
      fetchAutoInterNews: 'autoInter/fetchAutoInterNews',
      fetchFavoriteNewsIds: 'news/fetchFavoriteNewsIds',
      fetchAddToFavoritesNews: 'news/addToFavoritesNews',
      fetchDeleteFromFavoritesNews: "news/deleteFromFavoritesNews",
    }),
    async addToFavorites(id) {
      if(!this.showFavoritePopups.includes(id)) {
        this.showFavoritePopups = [...this.showFavoritePopups, id];
        setTimeout( () => {
          this.showFavoritePopups = this.showFavoritePopups.filter(el => el !== id)
        } ,2000)
        await this.fetchAddToFavoritesNews(id)
      }
    },
    async deleteFromFavorites(id){
      await this.fetchDeleteFromFavoritesNews(id)
    }
  },
  async mounted() {
    await this.fetchFavoriteNewsIds()
    await this.fetchAutoInterNews()
  },
  i18n: {
    ...translate
  }
};
