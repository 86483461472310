export default {
	messages: {
		ru: {
			'html.buAuto.byavto': 'Б/у авто',
			'catalogCar.newCars': 'Новые авто',
			'Наконец-то!': 'Наконец-то!',
			'Покупайте и продавайте авто без ограничений': 'Покупайте и продавайте авто без ограничений'
		},
		uk: {
			'html.buAuto.byavto': 'Вживані авто',
			'catalogCar.newCars': 'Нові авто',
			'Наконец-то!': 'Нарешті!',
			'Покупайте и продавайте авто без ограничений': 'Купуйте та продавайте авто без обмежень'
		},
	}
};
