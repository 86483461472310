export default {
	messages: {
		ru: {
			'Пошук': 'Поиск',
		},
		uk: {
			'Пошук': 'Пошук',
		},
	}
};
