import {mapGetters, mapActions} from 'vuex';

import Autocomplete from '../Autocomplete/index.vue';

export default {
	name: 'Models',
	i18n: require('./i18n').default,
	data: function () {
		return {
			mounted: false,
			suggestsRaw: [],
			currentSuggest: '',
		};
	},
	components: {
		'vue-autocomplete': Autocomplete
	},
	computed: {
		...mapGetters('autoInter/search', {
			currentModel: 'model',
			models: 'models',
			brand: 'brand'
		}),

		options() {
			return this.mounted ? this.models.sort((a, b) => a.name.localeCompare(b.name, 'en')) : []
		},

		selected: {
			get: function () {
				return this.currentModel;
			},
			set: function (id) {
				const selectedModel = this.options.find(el => el.id === Number(id));
				this.updateSearchFieldValue({ field: 'model', 'value': selectedModel || null });
			}
		},
		selectedName() {
			return this.selected?.name || ''
		},
	},
	methods: {
		...mapActions('autoInter/search', {
			updateSearchFieldValue: 'updateSearchFieldValue'
		}),
		onSet(value) {
			this.selected = value
		},
		ga() {
			this.gaEvent('AutoInterPageNew', 'ClickOn_Model', 'SearchForm');
		},

	},
	mounted() {
		this.mounted = true;
	},
};
