import {mapGetters, mapActions} from 'vuex';
import {cloneDeep} from '../../../../../../helpers/_lodash';
import getFromSearchHistory from '../../../../../helpers/getFromSearchHistory';
import {suggestModel} from '../../../../../store/queries/suggest';

import Autocomplete from '../Autocomplete/index.vue';

export default {
	name: 'Models',
	i18n: require('./i18n').default,
	props: {
		search_type: String,
	},
	data: function () {
		return {
			mounted: false,
			suggestsRaw: [],
			currentSuggest: '',
		};
	},
	components: {
		'vue-autocomplete': Autocomplete
	},
	computed: {
		...mapGetters({
			brandsAll: 'shared/brands/getAll',
			brandInfo: 'shared/brands/brand',
			modelsAll: 'shared/models/getAll',
			modelsForBrand: 'shared/models/getByBrand',
			popularModels: 'shared/models/getPopular',
			category: 'search/state/getCategory',
			getSpecificBrand: 'search/state/getSpecificBrand',
			getSpecificModel: 'search/state/getSpecificModel'
		}),
		firstBrand() {
			return this.getSpecificBrand(0);
		},
		optionsRaw() {
			return Object.values(this.modelsForBrand(this.firstBrand, this.category) || {});
		},
		options() {
			return this.optionsRaw.filter(({count} = {}) => count).sort((a, b) => a.name.localeCompare(b.name, 'en'));
		},
		optionsSearched() {
			if (this.mounted) {
				const idsSearched = [...new Set(getFromSearchHistory('model'))];
				const options = this.optionsRaw;
				return idsSearched.reduce((result, item) => {
					const optionSearched = options.find(({value}) => value === item);
					if (optionSearched) {
						result.push(Object.assign({}, optionSearched));
					}
					return result;
				}, []);
			} else {
				return [];
			}
		},
		optionsPopular() {
			if(this.firstBrand) {
				return this.popularModels(this.firstBrand, this.category);
			} else {
				return [];
			}
		},
		selected: {
			get: function () {
				return this.getSpecificModel(0) || 0;
			},
			set: function (value) {
				this.setOnly(value);
			}
		},
		selectedName() {
			let {name} = this.modelsAll[this.selected]
			|| this.suggests.find(({value}) => Number(this.selected) === Number(value))
			|| {};
			return name;
		},
		suggests() {
			if (this.mounted) {
				try {
					return [...new Set(getFromSearchHistory('model'))]
						.reduce((result, item) => {
							const index = result.indexOf(result.find(({value}) => Number(value) === Number(item)));
							if (index !== -1) {
								let options = result
									.splice(index, 1)
									.map((item = {}) => {
										item.searched = true;
										return item;
									});
								result = options.concat(...result);
							}
							return result;
						}, cloneDeep(this.suggestsRaw));
				} catch (error) {
					// console.error(error);
				}
			}
			return this.suggestsRaw;
		}
	},
	watch: {
		firstBrand(value) {
			if(value) {
				return this.fetch({category: this.category, brand: this.firstBrand, search_type: this.search_type})
					.then(() => {
						return this.fetchPopular({category: this.category, brand: this.firstBrand, search_type: this.search_type});
					});
			}
		},
		selected() {
			if (this.selected === 0) {
				this.suggesting = '';
			}
		}
	},
	methods: {
		...mapActions({
			fetch: 'shared/models/fetchByBrand',
			fetchPopular: 'shared/models/fetchPopular',
			setOnly: 'search/state/setOnlyModel',
		}),
		onSet(value) {
			this.setOnly(value);
		},
		onSuggest(payload) {
			this.currentSuggest = payload;
			this.suggestsRaw.splice(0, Infinity);
			return suggestModel({text: payload, category: this.category, brand: this.firstBrand}, this.search_type)
				.then((response) => {
					if (this.currentSuggest === payload) {
						this.suggestsRaw.splice(0, Infinity, ...response);
					}
				})
		},
		ga() {
			this.gaEvent('MainPageNew', 'ClickOn_Model', 'SearchForm');
		}
	},
	mounted() {
		this.mounted = true;
	}
};
