export default {
    messages: {
        uk: {
            'Автомобілі з Європи, Кореї та США': 'Автомобілі з Європи, Кореї та США',
            'Автомобілі з': 'Автомобілі з  {country} ({count})'
        },
        ru: {
            'Автомобілі з Європи, Кореї та США': 'Автомобили из Европы, Кореи та США',
            'Автомобілі з': 'Автомобили из {country} ({count})',
        }
    }
}
