import { mapGetters, mapActions } from "vuex";
import translate from "./i18n";

export default {
    name: 'ListPartnersCountries',
    computed: {
        ...mapGetters('autoInter', {
            countryPartners: 'countryPartners',
            countryPartnersLoading: 'countryPartnersLoading'
        }),
    },
    methods: {
        ...mapActions('autoInter', {
            fetchCountryPartners: 'fetchCountryPartners'
        }),
    },
    async mounted() {
        await this.fetchCountryPartners()
    },
    serverPrefetch() {
        return this.fetchCountryPartners()
    },
    i18n: {
        ...translate
    },
};
