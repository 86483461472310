import translate from "./i18n";

export default {
    name: "AutoInterRotatorItem",
    props:{
        'item': {
            type: Object,
            required: true
        },
        'index': {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            imageLoaded: false
        }
    },
    i18n: {
        ...translate
    },
    mounted() {
        if(!this.item.photos?.main?.id) {
            this.imageLoaded = true
        }
    }
}
