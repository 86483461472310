export default {
	messages: {
		ru: {
			'mainPage.filters.all': 'Все',
			'common.viewed': 'Просмотренные',
		},
		uk: {
			'mainPage.filters.all': 'Усі',
			'common.viewed': 'Переглянуті',
		},
	}
};
