import { mapActions, mapGetters } from "vuex";
import translate from './i18n'

export default {
  name: 'AutoInterRotator',
  data() {
    return {
      page: 0,
    };
  },
  components: {
    'auto-inter-rotator-item': require('./Item/index.vue').default
  },
  computed: {
    ...mapGetters('autoInter', {
      autoInterCars: 'autoInterCars',
      autoInterCarsLoading: 'autoInterCarsLoading',
    }),
    prevBtnDisabled() {
      return this.page <= 0;
    },
    desktopList() {
      return this.autoInterCars.length > 6 ? this.autoInterCars.slice(-5) : this.autoInterCars
    },
    mobileList() {
      const list = [...this.autoInterCars]
      list.splice(1, 0, {component: 'slot', slotName: 'bannerFromSearchForm'})
      return list
    },
    list() {
      return this.isDesktop
          ? this.desktopList
          : this.mobileList
    }
  },
  methods: {
    ...mapActions('autoInter',{
      fetchAutoInterCars: 'fetchAutoInterCars',
    }),
    async onPrev() {
      if (this.prevBtnDisabled) return;
      this.page--;
      await this.fetchAutoInterCars({ page: this.page });
    },
    async onNext() {
      if (!this.autoInterCars) return;
      this.page++;
      await this.fetchAutoInterCars({ page: this.page });
    }
  },
  async mounted() {
    await this.fetchAutoInterCars({ page: this.page });
  },
  i18n: {
    ...translate
  }
};
