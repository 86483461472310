import isChildElement from '../../../../../helpers/isChildElement';
import WindowEventWatcher from '../../../../../helpers/WindowEventWatcher';
import loopify from '../../../../../../app/helpers/loopify';

let watcher = new WindowEventWatcher('click');

export default {
	name: 'Autocomplete',
	i18n: require('./i18n').default,
	metaInfo() {
		return {
			bodyAttrs: {
				'class': this.mobilePopupActive ? 'autocomplete-popup' : ''
			}
		};
	},
	data: function () {
		return {
			isOptionsHidden: true,
			suggesting: false,
			text: '',
			hoveredIndex: -1,
			hoveredSuggestIndex: -1,
			suggestsList: []
		};
	},
	props: [
		'idPostfix', 'labelText',
		'selected', 'selectedName',
		'options', 'optionsTitle',
		'optionsPopular', 'optionsPopularTitle',
		'optionsSearched', 'optionsSearchedTitle',
		'suggests',
		'emptyText',
		'active', 'ga'
	],
	components: {
		'vue-list': require('./List/index.vue').default,
		'vue-suggest': () => import('./Suggest/index.vue')
	},
	computed: {
		mobilePopupActive() {
			return !this.isOptionsHidden && this.isMobile;
		},
		optionsSafe() {
			return Array.isArray(this.options) ? this.options : [];
		},
		optionsSearchedSafe() {
			return Array.isArray(this.optionsSearched) ? this.optionsSearched : [];
		},
		optionsPopularSafe() {
			return Array.isArray(this.optionsPopular) ? this.optionsPopular : [];
		},
		suggestsSafe() {
			return Array.isArray(this.suggests) ? this.suggests : [];
		},
		allOptions() {
			return [...this.optionsSearchedSafe, ...this.optionsPopularSafe, ...this.optionsSafe];
		},
		allOptionsLength() {
			return this.allOptions.length;
		}
	},
	watch: {
		selected() {
			if (this.selected === 0) {
				this.suggesting = false;
				this.text = '';
			} else {
				this.text = this.selectedName;
			}
		}
	},
	methods: {
		open() {
			if (this.ga) {
				this.ga();
			}
			this.showOptionsList();
		},
		toggleOptionsList() {
			this.isOptionsHidden = !this.isOptionsHidden;
		},
		hideOptionsList() {
			this.suggesting = false;
			this.isOptionsHidden = true;
			this.text = this.selectedName;
		},
		showOptionsList() {
			this.isOptionsHidden = false;
		},
		selectById(id) {
			this.$emit('set', id);
			this.suggesting = false;
			this.hideOptionsList();
		},
		resetInput() {
			this.suggesting = false;
			this.$emit('set', 0);
			this.hoveredIndex = -1;
			this.hoveredSuggestIndex = -1;
			this.text = '';
			this.showOptionsList();
		},
		input(event) {
			const {target: {value} = {}} = event;
			if (value) {
				// Костыль для из-за странного поведения chrome на мобильном
				this.text = value;
				this.$emit('suggest', value);
				this.suggesting = true;
				this.showOptionsList();
			} else {
				this.resetInput();
			}
		},
		keydown(event) {
			switch (event.keyCode) {
				case 9: { // tab
					this.hideOptionsList();
					return;
				}
				case 27: { // esc
					this.hideOptionsList();
					return;
				}
				case 38: { // up
					this.showOptionsList();
					if (this.suggesting) {
						const index = loopify(--this.hoveredSuggestIndex, this.suggests.length);
						this.hoveredSuggestIndex = Number.isFinite(index) ? index : -1;
					} else {
						const index = loopify(--this.hoveredIndex, this.allOptionsLength);
						this.hoveredIndex = Number.isFinite(index) ? index : -1;
					}
					event.preventDefault();
					return;
				}
				case 40: { // down
					this.showOptionsList();
					if (this.suggesting) {
						const index = loopify(++this.hoveredSuggestIndex, this.suggests.length);
						this.hoveredSuggestIndex = Number.isFinite(index) ? index : -1;
					} else {
						const index = loopify(++this.hoveredIndex, this.allOptionsLength);
						this.hoveredIndex = Number.isFinite(index) ? index : -1;
					}
					event.preventDefault();
					return;
				}
				case 13: { // enter
					if (this.suggesting) {
						if (this.hoveredSuggestIndex !== -1) {
							const index = this.hoveredSuggestIndex;
							const {value} = this.suggestsList[index] || {};
							this.selectById(Number.parseInt(value));
						}
					} else {
						if (this.hoveredIndex !== -1) {
							let {[this.hoveredIndex]: {value} = {}} = this.allOptions;
							this.selectById(value);
						}
					}
					event.preventDefault();
					return;
				}
			}
		},
		suggestsListed(payload) {
			this.suggestsList = payload;
		}
	},
	mounted() {
		this.hideOptionsList();
		let node = this.$refs.pseudoselect;
		const hide = function (event) {
			const {target} = event;
			if (target !== node && !isChildElement(node, target)) {
				this.hideOptionsList();
			}
		}.bind(this);
		watcher.subscribe(this.$refs.pseudoselect, hide);
	},
	beforeDestroy() {
		watcher.unsubscribe(this.$refs.pseudoselect);
	}
};
