export default {
    messages: {
        uk: {
            'Під пригон': 'Під пригон',
            'тис км': '{race} тис. км',
            'Авто не в Україні': 'Авто не в Україні'
        },
        ru: {
            'Під пригон': 'Под пригон',
            'тис км': '{race} тыс. км',
            'Авто не в Україні': 'Авто не в Украине'
        }
    }
}
