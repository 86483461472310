import translate from "./i18n";

export default {
	name: 'SearchTabs',
	props: {
		value: {
			type: String,
			default: 'all',
		},
		types: {
			type: Array,
			required: true
		},
	},
	i18n: {
		...translate
	},
};
