export default {
    messages: {
        uk: {
            'Авто з Європи': 'Авто з Європи - купити автомобіль за кордону',
            'Продаж автомобілів з-за кордону': 'Продаж автомобілів з-за кордону на нашому автосайті це безліч оголошень автомобілів з Європи. На AUTO.RIA можна подивитися пропозиції купівлі авто з-за кордону і підібрати собі автомобіль з Європи.',
            'авто, auto': 'авто, auto, продаж авто, автомобілі, автосалони, авторинок, базар авто, каталог автомобілів',
            'Авто з-за кордону з Європи, Кореї та США': 'Авто з-за кордону з Європи, Кореї та США'
        },
        ru: {
            'Авто з Європи': 'Авто из Европы - купить автомобиль из-за рубежа',
            'Продаж автомобілів з-за кордону': 'Продажа автомобилей из-за рубежа на нашем автосайте – это множество объявлений автомобилей из Европы. На AUTO.RIA можно посмотреть предложения покупки авто из-за рубежа и подобрать себе автомобиль из Европы.',
            'авто, auto': 'авто, auto, продажа авто, автомобили, автосалоны, авторынок, базар авто, каталог автомобилей',
            'Авто з-за кордону з Європи, Кореї та США': 'Авто из-за рубежа из Европы, Кореи и США'
        },
    }
}
