export default {
	name: 'Suggest',
	props: ['isOptionsHidden', 'optionsSearched', 'hoveredIndex', 'suggests', 'selected'],
	data: function () {
		return {}
	},
	computed: {
		suggestsList() {
			let result;
			const searched = Array.isArray(this.optionsSearched) ? this.optionsSearched : [];
			if (searched.length) {
				const data = this.suggests.reduce((result, item = {}) => {
					let {value} = item;

					let index = searched.findIndex(({value: valueSearched} = {}) => value == valueSearched);
					index === -1 ?
						result.suggested.push(item) :
						result.searched.push(Object.assign({searched: true}, item));
					return result
				}, {
					searched: [], suggested: []
				});

				result = data.searched.concat(...data.suggested);

				result = result.map((item, i) => {
					item._ = i;
					return item
				});
			} else {
				result = this.suggests
			}

			this.$emit('suggestsListed', result);
			return result;
		}
	},
	methods: {
		select(event) {
			const {target: {dataset: {value} = {}} = {}} = event;
			this.$emit('set', value)
		},
	},
	updated() {
		if (!this.isOptionsHidden) {
			let item = this.$el.querySelector('.active');
			if (item) {
				this.$el.scrollTop = item.offsetTop - 50;
			}
		}
	}
};
