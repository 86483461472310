import {mapGetters, mapActions} from 'vuex';

export default {
	i18n: require('./i18n').default,
	components: {
		Autocomplite: require('../Autocomplete2/index.vue').default
	},
	data: function () {
		return {
			mode: 'list',
			isOptionsHidden: true,
			suggesting: '',
			selectedName: '',
			searchedValue: ''
		};
	},
	computed: {
		...mapGetters({
			regions: 'shared/locations/l10n',
			langId: 'lang/id',
			selectedPlace: 'search/state/getSpecificLocation',
			suggestsCity: 'shared/locations/suggestsCity',
			region: 'shared/locations/region',
			city: 'shared/locations/cityl10n'
		}),
		optionsRegionsList() {
			let regions =  [...this.normalizedRegions]

			// Забираем Киев из списка
			let kievIndex = regions.findIndex(({value} = {}) => Number(value) === 10);
			const [kiev] = kievIndex !== -1 ? (regions.splice(kievIndex, 1)) : [];
			regions = regions.sort((a, b) => a.name.localeCompare(b.name, 'en'));
			// Делаем Киев первым в списке
			if (kiev) {
				regions.unshift(kiev);
			}
			return [{label: this.$t('html.buAuto.region'), options: regions.map(({value, name: label} = {}) => ({value, label}))}];
		},
		suggestsCityList() {
			const filteredRegions = this.normalizedRegions.filter(el => el.name.toLowerCase().trim().startsWith(this.searchedValue))

			let countingSimilarCityNames = this.suggestsCity
				.reduce((acc, el) => {
					if(!acc.counter[el.name]) {
						acc.counter[el.name] = 1
					}
					else {
						acc.counter[el.name]++
						acc.repeatedNames.push(el.name)
					}

					return acc
			}, {
				counter: {},
				repeatedNames: []
			})

			let hydratedNameByRegion = this.suggestsCity.map(el => {
				if(countingSimilarCityNames.repeatedNames.includes(el.name)) {
					el.name = `${el.name} (${this.regions[el.state].name})`
				}
				return el;
			})

			const joinedCitiesAndRegions = [...hydratedNameByRegion, ...filteredRegions]

			return [{options: Array.isArray(this.suggestsCity) ? joinedCitiesAndRegions.map(({value, name: label} = {}) => ({value, label})) : []}];
		},
		optionsList() {
			return this.mode === 'list' ? this.optionsRegionsList : this.suggestsCityList;
		},
		selected: {
			get: function () {
				return this.selectedPlace(0) || {};
			},
			set: function (value) {
				this.setOnlyState(value);
			}
		},
		selectedId() {
			return this.mode === 'list' ? this.selected.state : this.selected.city;
		},
		normalizedRegions() {
			return Object.values(this.regions).reduce((acc, el) => {
				acc.push({
					...el,
					name: el.name + this.$t('обл.')
				})
				return acc
			},[]);
		}
	},
	methods: {
		...mapActions({
			fetch: 'shared/locations/fetchCenters',
			suggestCity: 'shared/locations/suggestCity',
			setOnlyState: 'search/state/setOnlyState',
			setOnlyCity: 'search/state/setOnlyCity'
		}),
		init() {
			return this.fetch();
		},
		ga() {
			this.gaEvent('MainPageNew', 'ClickOn_Region', 'SearchForm');
		},
		selectAutocomplite(value) {
			if (value === 0) {
				this.setOnlyState();
				this.mode = 'list';
			} else {
				if (this.mode === 'list') {
					this.setOnlyState(value);
				} else {
					this.setOnlyCity(value);
				}
			}
		},
		inputAutocomplite(value) {
			if (value) {
				this.mode = 'autocomplete';
				this.setOnlyState();
				this.suggestCity(value);
				this.searchedValue = value.toLowerCase().trim();
			} else {
				this.mode = 'list';
			}
		},
		escAutocomplite() {
			if (this.mode === 'autocomplete' && !this.selectedId) {
				this.mode = 'list';
			}
		}
	},
	serverPrefetch() {
		return this.init();
	},
	mounted() {
		this.mounted = true;
		this.init();
	}
};
